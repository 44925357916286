'use strict';

module.exports = {
      
  RandomNumber (from = 1, to = 2, round = true) {
    if (round === true) {
      return Math.round(Math.random() * (to - from) + from);  
    }
    return Math.random() * (to - from) + to;
  },
  
  NumberMinDigits (number, minDigits = 1) {
    const numberString = number.toString();
    if (numberString.length >= minDigits) {
      return numberString;
    }
    
    return `0`.repeat(minDigits - numberString.length) + number.toString();
  },
  
  roundMaxDigits(val, maxDigits, failVal = '?') {
    if (typeof(val) !== "string" && typeof(val) !== "number") {
      return failVal
    } 
    
    if (isNaN(parseInt(val)) ) {
      return failVal
    }
    
    if (val.toString() == parseInt(val).toString()) {
      return parseInt(val)
    }
    
    return parseFloat(val).toFixed(maxDigits)
  },
  
  toCurrency (amount, currencyType = "en-us", options = { style: "currency", currency: "USD", maximumFractionDigits: 2 } ) {
    const currencyFormatter = new Intl.NumberFormat(currencyType, options);
    return currencyFormatter.format(amount);
  }
  
}

/*
 * Example:
 // const { toCurrency } = require('../../classes/general/numbers');
 // console.info(`toCurrency:`, toCurrency(123.32324));
*/